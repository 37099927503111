import { deepCopy } from '@/utils/json-parser'

export default {
  methods: {
    getAssetsData() {
      const data = {}
      const assetsData = this.assets ? deepCopy(this.assets) : deepCopy(this.feedAssets)
      Object.keys(assetsData).forEach(key => {
        data[key] = this[key]
      })

      return data
    },

    storeAssets(target, assetAction = 'STORE_ASSETS') {
      this.$store.commit(`${target}/${assetAction}`, this.getAssetsData())
    },

    clearAssets(target, assetAction = 'CLEAR_ASSETS') {
      this.$store.commit(`${target}/${assetAction}`)
    },

    initAssets() {
      const assetsData = this.assets ? deepCopy(this.assets) : deepCopy(this.feedAssets)
      Object.keys(assetsData).forEach(key => {
        this[key] = assetsData[key]
      })
    }
  },

  created() {
    this.initAssets()
  }
}
