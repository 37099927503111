export default {
  methods: {
    isNextPage(total) {
      return total > this.params.perPage
    },

    getPageCount(totalPages, total) {
      return totalPages || Math.ceil(total / this.params.perPage)
    }
  }
}
