<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase">transactions</h1>

    <div class="box box--sm box--gray radius-bottom-0 mt-16 d-flex">
      <multiple-select v-model="filterValues" :options="filterOptions" @filter-callback="onGetTnxByFilter($event)" />

      <div class="d-flex">
        <div class="search-box-group">
          <search-box v-model="searchParams.searchText" holder-text="Search" @on-search="onGetTransactions" />

          <selection-box
            v-model="searchParams.searchTarget"
            :options="transactionTargetOptions"
            :is-align-right="true"
            :on-check="onGetTransactionsByTarget"
            selected-box-class="is-lg search-box__filter"
          />
        </div>
      </div>

      <selection-box
        v-model="params.perPage"
        :options="pagerOptions"
        :is-align-right="true"
        :on-check="onGetTransactions"
      />
    </div>

    <p class="p-16" v-html="filterByStr" />

    <div
      v-if="transactions && transactions.data"
      class="table-wrapper table-wrapper--fluid table-wrapper--expand radius-top-0"
    >
      <transactions :show-tnx-type="showTnxType" :show-error="isErrorTnx" :transactions="transactions.data" />

      <div class="text-center mt-32">
        <p>{{ transactions.total | format_transaction_count_text }} in total</p>

        <pagination
          v-if="isNextPage(transactions.total)"
          v-model="page"
          :page-count="getPageCount(transactions.totalPages, transactions.total)"
          :page-range="5"
          :click-handler="clickPagerCallback"
        />
      </div>
    </div>

    <div v-else class="box box--lg box--white radius-top-0 mt-0">
      <no-result />
    </div>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import pagination from '@/mixins/pagination'
import handleAssets from '@/mixins/handle-assets'
import toggleLoading from '@/mixins/toggle-loading'
import handleFilterBox from '@/mixins/handle-filter-box'
import {
  PAGER_OPTIONS,
  TRANSACTION_SEARCH_TARGET_OPTIONS,
  TRANSACTION_STATUS_OPTIONS,
  STAKING_TOKO_OPTIONS,
  SWAP_TOKO_OPTIONS,
  TNX_NETWORK_TYPE_OPTIONS,
  TRANSACTION_STATUS_FILTER_OPTIONS
} from '@/utils/data-sets'
import { TRANSACTION_OPTION_DATA_TYPE, TNX_STATUS, TNX_NETWORK_TYPES } from '@/utils/key-mapping'
const Transactions = () => import('@/components/Transactions')
const NoResult = () => import('@/components/NoResult')
const Pagination = () => import('@/components/Pagination')
const SelectionBox = () => import('@/components/SelectionBox')
const SearchBox = () => import('@/components/SearchBox')
const MultipleSelect = () => import('@/components/MultipleSelect')

export default {
  data() {
    return {
      params: {
        perPage: 20,
        address: null,
        tnxHash: null,
        optionsDataType: 0,
        status: TNX_STATUS.invalid,
        networkType: TNX_NETWORK_TYPES.invalid,
        contractAddress: ''
      },
      searchParams: {
        searchTarget: 0,
        searchText: ''
      },
      page: 1,
      filterValues: [],
      pagerOptions: PAGER_OPTIONS,
      transactionTargetOptions: TRANSACTION_SEARCH_TARGET_OPTIONS,
      transactionStatusOptions: TRANSACTION_STATUS_OPTIONS,
      transactionOptionDataType: TRANSACTION_OPTION_DATA_TYPE
    }
  },

  mixins: [pagination, handleAssets, toggleLoading, handleFilterBox],

  components: {
    Transactions,
    NoResult,
    Pagination,
    SelectionBox,
    SearchBox,
    MultipleSelect
  },

  computed: {
    ...mapState('transaction', ['transactions', 'assets']),

    ...mapState('smartContract', ['stakeSmartContract', 'stakeV2SmartContract']),

    isStakingTransaction() {
      return this.$route.meta.type === TRANSACTION_OPTION_DATA_TYPE.staking
    },

    isSwapTokoTransaction() {
      return this.$route.meta.type === TRANSACTION_OPTION_DATA_TYPE.swapToko
    },

    filterOptions() {
      let options = [
        {
          label: 'Network Type',
          hint: 'networkType',
          options: TNX_NETWORK_TYPE_OPTIONS
        },
        {
          label: 'Status Filter',
          hint: 'tnxStatus',
          options: TRANSACTION_STATUS_FILTER_OPTIONS
        }
      ]

      if (this.isSwapTokoTransaction) {
        options = [
          {
            label: 'Swap By',
            hint: 'swapBy',
            options: SWAP_TOKO_OPTIONS
          },
          ...options
        ]
      }

      if (this.isStakingTransaction) {
        options = [
          {
            label: 'Toko Version',
            hint: 'tokoVersion',
            options: STAKING_TOKO_OPTIONS
          },
          ...options
        ]
      }

      return options
    },

    contractAddressParam() {
      let address = ''

      if (this.isStakingTransaction) {
        if (this.params.tokoVer === 1) address = this.stakeSmartContract.address
        if (this.params.tokoVer === 2) address = this.stakeV2SmartContract.address
      }

      if (this.isSwapTokoTransaction) {
        if (this.params.tokoVer === 1) address = process.env.VUE_APP_ETHERSCAN_TOKOIN_V1_ADDRESS
        if (this.params.tokoVer === 2) address = process.env.VUE_APP_ETHERSCAN_TOKOIN_ADDRESS
      }

      return address
    },

    searchTarget() {
      return parseInt(this.searchParams.searchTarget)
    },

    optionDataType() {
      return this.$route.meta.type
    },

    isErrorTnx() {
      return this.$route.name === 'TransactionsErrorActions' ? 1 : 0
    },

    showTnxType() {
      return (
        this.optionDataType === this.transactionOptionDataType.staking ||
        this.optionDataType === this.transactionOptionDataType.crowdfundingOrder
      )
    },

    tnxRequestParams() {
      let address, tnxHash
      if (this.searchParams.searchText) {
        tnxHash = this.searchTarget === 0 ? this.searchParams.searchText : this.params.tnxHash
        address = this.searchTarget === 1 ? this.searchParams.searchText : this.params.address
      }

      return {
        page: this.page,
        limit: this.params.perPage,
        status: this.params.status,
        optionsDataType: this.optionDataType,
        contractAddress: this.params.contractAddress,
        networkType: this.params.networkType,
        isError: this.isErrorTnx,
        address,
        tnxHash
      }
    },

    filterByStr() {
      let filterStr = '<span class="text-purple text-extra-bold text-italic mr-4">Filter By:</span> '
      let networkTypeStr = '<span class="text-extra-bold text-italic mr-8">All</span>',
        tnxStatusStr = '<span class="text-extra-bold text-italic mr-8">All</span>',
        tokoVersionStr = '<span class="text-extra-bold text-italic mr-8">All Version</span>',
        swapTokoStr = '<span class="text-extra-bold text-italic mr-8">All</span>'
      const filterValues = this.filterValues

      for (let i = 0; i < filterValues.length; i++) {
        if (filterValues[i].category === 'networkType') {
          if (filterValues[i].value === TNX_NETWORK_TYPES.invalid)
            networkTypeStr = '<span class="text-extra-bold text-italic mr-8">All</span>'
          if (filterValues[i].value === TNX_NETWORK_TYPES.ethereum)
            networkTypeStr = '<span class="text-extra-bold text-italic mr-8">Ethereum</span>'
          if (filterValues[i].value === TNX_NETWORK_TYPES.bsc)
            networkTypeStr = '<span class="text-extra-bold text-italic mr-8">Binance Smart Chain</span>'
          if (filterValues[i].value === TNX_NETWORK_TYPES.btc)
            networkTypeStr = '<span class="text-extra-bold text-italic mr-8">BTC</span>'
        }

        if (filterValues[i].category === 'tnxStatus') {
          if (filterValues[i].value === TNX_STATUS.pending)
            tnxStatusStr = '<span class="text-extra-bold text-italic mr-8">Pending</span>'
          if (filterValues[i].value === TNX_STATUS.success)
            tnxStatusStr = '<span class="text-extra-bold text-italic mr-8">Success</span>'
          if (filterValues[i].value === TNX_STATUS.failed)
            tnxStatusStr = '<span class="text-extra-bold text-italic mr-8">Failed</span>'
          if (filterValues[i].value === TNX_STATUS.error)
            tnxStatusStr = '<span class="text-extra-bold text-italic mr-8">Error</span>'
          if (filterValues[i].value === TNX_STATUS.notFound)
            tnxStatusStr = '<span class="text-extra-bold text-italic mr-8">Not Found</span>'
        }

        if (filterValues[i].category === 'swapBy') {
          if (filterValues[i].value === 1)
            swapTokoStr = '<span class="text-extra-bold text-italic mr-8">Transfer By User</span>'
          if (filterValues[i].value === 2)
            swapTokoStr = '<span class="text-extra-bold text-italic mr-8">Transfer By Admin</span>'
        }

        if (filterValues[i].category === 'tokoVersion') {
          if (filterValues[i].value === 1)
            swapTokoStr = '<span class="text-extra-bold text-italic mr-8">Toko Version 1</span>'
          if (filterValues[i].value === 2)
            swapTokoStr = '<span class="text-extra-bold text-italic mr-8">Toko Version 2</span>'
        }
      }

      filterStr = `${filterStr} Network: ${networkTypeStr} | Tnx. Status: ${tnxStatusStr}`
      if (this.isStakingTransaction) {
        filterStr = `${filterStr} | Toko Version: ${tokoVersionStr}`
      }

      if (this.isSwapTokoTransaction) {
        filterStr = `${filterStr} | Swap: ${swapTokoStr}`
      }

      return filterStr
    }
  },

  methods: {
    ...mapActions('transaction', ['getTransactions']),

    ...mapMutations('transaction', ['CLEAR_TRANSACTIONS_DATA']),

    ...mapActions('smartContract', ['getSmartContracts']),

    onGetTransactionsByTarget() {
      if (!this.searchParams.searchText) return
      this.onGetTransactions()
    },

    onGetTransactions() {
      this.toggleLoading()
      this.page = 1
      this.getTransactions(this.tnxRequestParams).finally(() => {
        this.toggleLoading()
      })
    },

    clickPagerCallback() {
      this.toggleLoading()
      this.getTransactions(this.tnxRequestParams).finally(() => {
        this.toggleLoading()
      })
    },

    onGetTnxByFilter(payload) {
      this.filterValues = payload
      for (let i = 0; i < payload.length; i++) {
        if (payload[i].category === 'networkType') {
          this.params.networkType = payload[i].value
        }

        if (payload[i].category === 'tnxStatus' || payload[i].category === 'status') {
          this.params.status = payload[i].value
        }

        if (this.isSwapTokoTransaction && payload[i].category === 'swapBy') {
          if (payload[i].value === 1) this.params.contractAddress = process.env.VUE_APP_ETHERSCAN_TOKOIN_V1_ADDRESS
          if (payload[i].value === 2) this.params.contractAddress = process.env.VUE_APP_ETHERSCAN_TOKOIN_ADDRESS
        }

        if (this.isStakingTransaction && payload[i].category === 'tokoVersion') {
          if (payload[i].value === 1) this.params.contractAddress = this.stakeSmartContract.address
          if (payload[i].value === 2) this.params.contractAddress = this.stakeV2SmartContract.address
        }
      }

      this.onGetTransactions()
    }
  },

  async created() {
    this['CLEAR_TRANSACTIONS_DATA']()
    try {
      await this.getSmartContracts()
      await this.getTransactions(this.tnxRequestParams)
    } catch (e) {
      this.toggleLoading()
      throw e
    }

    this.toggleLoading()
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === 'Transaction') {
      this.storeAssets('transaction')
      next()
    } else {
      this.clearAssets('transaction')
      next()
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.toggleLoading()
      // reset params
      this.clearAssets('transaction')
      this.initAssets()

      this.getTransactions(this.tnxRequestParams).finally(() => {
        this.toggleLoading()
      })
    }
  }
}
</script>
