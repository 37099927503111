export default {
  computed: {
    orderBy() {
      const order = this.filterValues.filter(item => item.category === 'order')

      if (order.length) return { sortBy: order[0].value, orderBy: order[0].orderBy }
      return { sortBy: 'created_at', orderBy: 'desc' }
    },

    statuses() {
      let statues = {}
      this.filterValues.forEach((item, index) => {
        if (item.category === 'status') statues[`status[${index}]`] = item.value
      })

      return statues
    }
  }
}
